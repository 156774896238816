import React,{useState,useEffect} from 'react'
import {useAlumno} from '../../../Context/alumnoContext'
import {formatearMontoMoneda,revertirMontoMoneda,getMesString} from '../../../Helpers/utilidades-globales'
import Axios from 'axios'
const regex_solo_numeros = /^[0-9]+([.])?([0-9][0-9]?)?$/;

export const useEgresos = ()=>{
    const {usuario,esCelular} = useAlumno()

    const [egreso,setEgreso] = useState({
        id:-1,
        mes:mesActual(),
        anio:crearVectorAnios()[0],
        gira:false,
        descripcion:'',
        observaciones:'',
        retirado:false,
        monto:'0,00'
    })

    const descripcion = 'Egreso'
    const [egresoString,setEgresoString] = useState('')
    const [procesando,setProcesando] = useState(false)
    const [finalizado,setFinalizado] = useState(false)
    const [egresos,setEgresos] = useState([])
    const [filtros,setFiltros] = useState({})
    const [periodos,setPeriodos] = useState({})
    const [conceptos,setConceptos] = useState([])
    const [montoFormateado,setMontoFormateado]=useState(null)

    useEffect(()=>{
        buscarConceptos()
        buscarEgresos()
    },[])

    useEffect(()=>{
        setMontoFormateado(isNaN(Number(egreso.monto))? '' : `$ ${formatearMontoMoneda(Number(egreso.monto))}`)
     },[egreso.monto])

     
    useEffect(()=>{
        const mesString = getMesString(egreso.mes);
        setEgreso({...egreso,observaciones:`Período: ${mesString}-${egreso.anio} ##`})
    },[egreso.anio,egreso.mes])

    const reinicializar = ()=>{
        setEgreso({ id:-1,
            mes:mesActual(),
            anio:crearVectorAnios()[0],
            gira:false,
            descripcion:'',
            observaciones:'',
            retirado:false,
            monto:'0,00'})

            setFinalizado(false)
            setProcesando(false)
    }

    const handleChangeTipoSalida = (e,value)=>{
        // este handle esta hecho para funcionar tanto con un Autocomplete de mui
        // como un select html o de mui
        let egresoStringAux = ''

        if(value){
            if(typeof(value)=='string'){ // si viene de un select
                setEgresoString(value)
            }else if(typeof(value)=='object'){ // si viene de un autocomplete
                setEgresoString(value.label)
            }
        }else{
            setEgresoString('')
        }

        if(e.target?.value){
            setEgreso({...egreso,id:Number(e.target.value),descripcion:''})
        }else if (value?.id){
            setEgreso({...egreso,id:Number(value.id),descripcion:''})
        }else{
            setEgreso({...egreso,id:-1,descripcion:''})
        }
    }

    const handleChangeMes = (e)=>{
        setEgreso({...egreso,mes:Number(e.target.value)})
    }

    const handleChangeAnio = (e)=>{
        const fecha = new Date()
        const anioActual = fecha.getFullYear()

        //Esta lógica se escribe para evitar que se seleccione un período mayor al
        // actual en base al año seleccionado y al mes.
        // En el componente de meses se deshabilitan los meses mayores al actual cuando
        // seleccionó el año en curso.
        // Pero hace falta esta lógica para controlar que sea correcto el perído cuando
        // cambió de año, por ejemplo si estamos en julio 2022 no puede seleccionar agosto 
        // en adelante para el año 2022 , pero si podría seleccionar agosto para el 2021
        // si luego de seleccionar agosto 2021 vuelve al año 2022 se aplica este control
        // y llevo el mes al actual programaticamente.

        if (anioActual==Number(e.target.value)){
            if(Number(egreso.mes)>mesActual()){
                setEgreso({...egreso,anio:Number(e.target.value),mes:mesActual()})
            }else{
                setEgreso({...egreso,anio:Number(e.target.value)})
            }
        }else{
            setEgreso({...egreso,anio:Number(e.target.value)})
        }

    }

    const handleChangeGira = (e)=>{
        setEgreso({...egreso,gira:e.target.value=='G' ? 1 : 0})
    }

    const handleChangeMonto = (e)=>{

        // if(Number(e.target.value)>1000000){
        //     return
        //  }

        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()=='')
        {
            setEgreso({...egreso, monto:e.target.value.trim()=='' ? '' : e.target.value})
        }

    }

    const handleChangeDescripcion = (e)=>{
        const descripcion = e.target.value.toUpperCase()
        setEgreso({...egreso, descripcion:descripcion})
        setEgresoString(descripcion)
    }

    const handleChangeObservaciones = (e)=>{
        const observaciones = e.target.value.toUpperCase()
        setEgreso({...egreso, observaciones:observaciones})
    }

    const datosOk = ()=>{


        if (egreso.id==-1){
            return [false,'Falta seleccionar el concepto a informar']
        }

        if (egreso.id==19 && egreso.descripcion.trim()==''){
            return [false,'El tipo de concepto requiere que especifique una descripción']
        }

        if (egreso.mes==-1){
            return [false,'Falta seleccionar un mes']
        }

        if (!egreso.monto){
            return [false,'Falta ingresar un monto']
        }

        if (Number(revertirMontoMoneda(egreso.monto))==0){
            return [false,'Falta ingresar un monto']
        }

        if (egreso.observaciones==undefined || !egreso.observaciones){
            return [false,'Falta ingresar una observación']
        }

        return [true,'']
    }

    const handleChangeOtroEgreso = (e)=>{
        alert('pido otro egreso')
    }

    const handleChangeRetirado = (e)=>{
        setEgreso({...egreso, retirado:e.target.checked ? true : false})
    }

    const enviarInforme = async (values)=>{

        try{

            setProcesando(true)
            const montoMoneda =  Number(revertirMontoMoneda(egreso.monto))
            
            const giraBoolean = egreso.gira==1 ? true :  false

            const objetoaEnviar = {...egreso,gira: giraBoolean ,monto:montoMoneda}
            
            const respuesta = await Axios.post(`/api/tablasgenerales/formulario/salida/${usuario.id_misionero}`,objetoaEnviar)
        
            setTimeout(() => {
                setProcesando(false)
                setFinalizado(true)
                setFiltros({})
                reinicializar()
                buscarEgresos()
            }, 1000);

            return 'El movimiento se grabó correctamente'
        }catch(err){
            setProcesando(false)
            if(err.response?.data?.message){
                alert(err.response.data.message)
            }else{
                alert('Se produjo un error al grabar el movimiento')
            }
            return 'Se produjo un error al grabar el movimiento'
        }

    }

    const detalles = ()=>{
        return [
                egreso.gira ? 'Movimiento de egreso en gira' : 'Movimiento de egreso en campo',
                egresoString,
                `${mesString(egreso.mes)} ${egreso.anio}`,'  ',
                `$ ${egreso.monto}`]
    }

    const resetearDescripcion = () => {
            setEgreso({...egreso,id:-1,descripcion:''});
    };

    const buscarEgresos = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/formularios/egresos/${usuario.id_misionero}`)
            setEgresos(data)
            cargarPeriodos(setPeriodos,data)
        }catch(err){
            console.log(err)
            alert('Se produjo un error al buscar el listado de movimientos')
        }
    }

    const buscarConceptos = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/tipos_salida`)
            setConceptos(data)
        }catch(err){
            console.log(err)
            alert('Se produjo un error al buscar conceptos y tipos de salida')
        }
    }

    const filtrar = (itemdelListado,filtros)=>{
        const arrayObjeto = Object.entries(filtros)
        let resultado = true

        // esta función de filtrado esta explicado en el hook usIngresos
        /*arrayObjeto.forEach(itemdelvectorFiltro=>{
            if(itemdelListado[itemdelvectorFiltro[0]]){ // evalúo si es igual o diferente solo si el campo existe, esto es porque se usa la misma función de filtrado para diferentes informes y puede haber primary keys que en algunos listados estén y en otros no, es importante este primer IF, por ejemplo id_tipo_salida no va a existir en un listado de ingresos por eso no habíra que evaluarlo aquí
                if(itemdelListado[itemdelvectorFiltro[0]]!=itemdelvectorFiltro[1]){
                    resultado = false
                }
            }
        })*/

        arrayObjeto.forEach((itemdelvectorFiltro,index)=>{
            if(itemdelListado[itemdelvectorFiltro[0]]!==undefined){ // evalúo si es igual o diferente solo si el campo existe, esto es porque se usa la misma función de filtrado para diferentes informes y puede haber primary keys que en algunos listados estén y en otros no, es importante este primer IF, por ejemplo id_tipo_salida no va a existir en un listado de ingresos por eso no habíra que evaluarlo aquí
                if(itemdelListado[itemdelvectorFiltro[0]]!=itemdelvectorFiltro[1] && itemdelvectorFiltro[0]!='observaciones' ){
                            resultado = false
                }
                if(itemdelvectorFiltro[0]=='observaciones' && !(itemdelListado[itemdelvectorFiltro[0]]?.toUpperCase()?.includes(itemdelvectorFiltro[1].toUpperCase()))){
                    resultado = false
                }
            }
        })

       return resultado
    }

    const formatearMontoBlur = ()=>{
        setEgreso({...egreso, monto:formatearMontoMoneda(Number(egreso.monto))})
    }

    const formatearMontoFoco = (e)=>{
        setEgreso({...egreso, monto:egreso.monto=='0,00' ? '' : revertirMontoMoneda(egreso.monto)})      
    }

    return {egreso,handleChangeTipoSalida,
            handleChangeAnio,
            handleChangeMes,
            handleChangeGira,
            handleChangeDescripcion,
            handleChangeMonto,
            datosOk,
            enviarInforme,
            detalles,handleChangeOtroEgreso,
            resetearDescripcion,crearVectorAnios,
            egresoString,
            procesando,
            finalizado,
            reinicializar,
            buscarEgresos,
            egresos:egresos.filter(item=>filtrar(item,filtros)),
            setFiltros,filtros,
            id_misionero: usuario.id_misionero,
            periodos,
            formatearMontoBlur,formatearMontoFoco,
            descripcion,
            conceptos,
            solicitarCategoria:true,
            montoFormateado,
            esCelular,handleChangeObservaciones,handleChangeRetirado} // egresos siempre solicita la categoría en campo o en gira, ingresos solo cuando es ofrenda es decir cuando requiere un donante
}

const mesActual = ()=>{
    const fecha = new Date()
    const mes = fecha.getMonth()

    return mes + 1
}

const mesString = (mes)=>{

    switch(mes){
        case  1 : return 'Enero'
        case  2 : return 'Febrero'
        case  3 : return 'Marzo'
        case  4 : return 'Abril'
        case  5 : return 'Mayo'
        case  6 : return 'Junio'
        case  7 : return 'Julio'
        case  8 : return 'Agosto'
        case  9 : return 'Septiembre'
        case  10 : return 'Octubre'
        case  11 : return 'Noviembre'
        default: return 'Diciembre'
    }

}

const crearVectorAnios = ()=>{
    const fecha = new Date()
    const anio = fecha.getFullYear()
    const anios = []

    for (let i=anio;i>anio-5;i--){
        anios.push(i)
    }

    return anios
}

const cargarPeriodos = (setPeriodos,data)=>{
    const periodos = new Map()

    data.forEach(item=>{
        const periodo = `${item.mesString} ${item.anio}`
        const objetoPeriodo = {mes:item.mesString,anio:item.anio}
        periodos.set(periodo,objetoPeriodo)
    })

    const mapPeriodosArray = Array.from(periodos.entries())
    const vectorPeriodos = mapPeriodosArray.map(item=>{return {id:item[1],label:item[0]}})
    
    setPeriodos(vectorPeriodos)
}